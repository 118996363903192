export const resources = {
  ORDERS: 'orders',
  PRODUCTS: 'products',
  USERS: 'users',
  TRADEDETAIL: 'tradedetail',
  DESTINATION: 'destination',
  TRANSACTIONINFO: 'transactioninfo',
  TERMINALDETAIL: 'terminaldetail',
  BANUMBER: 'banumber',
  COMPONENTS: 'components',
  INTERMEDIATE: 'intermediate',
  INTERMEDIEATEINVENTORYOF: 'intermediateinventoryof',
  INTERMEDIATEPRODUCEDAT: 'intermediateproducedat',
  EDITINTERMEDIEATEINVENTORYOF: 'editintermediateinventoryof',
  EDITINTERMEDIATEPRODUCEDAT: 'editintermediateproducedat',
  PRODUCESDATE: 'producesdate',
  PROJECTEDPRODUCESDATE: 'projectedproducesdate',
  TERMINAL: 'terminal',
  RECIPE: 'recipe',
  INVENTORYRECIPE: 'inventoryrecipe',
  PROMPTRECIPE: 'promptrecipe',
  RECIPECOMPONENT: 'recipecomponent',
  RECIPEPRODUCT: 'recipeproduct',
};
