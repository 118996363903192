import { Route } from '@fhr/react';
import { orderRoutes } from './orderRoutes';
import { terminalRoutes } from './terminalRoutes';
import { userRoutes } from './userRoutes';
import { productRoutes } from './productRoutes';
import { recipeRoutes } from './recipeRoutes';
import { intermediateRoutes } from './intermediateProducedAtRoutes';

export const appRoutes = (): Route[] => {
    return [...orderRoutes(), ...productRoutes(), ...recipeRoutes(), ...terminalRoutes(),...intermediateRoutes(), ...userRoutes()];
};
