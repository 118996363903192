import { Route } from '@fhr/react';
import { Blender, Mediation } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';

const IntermediatePage = React.lazy(() => import('../pages/intermediate/IntermediatePage'));

export const intermediateRoutes = (): Route[] => {
  return [
    {
      path: 'intermediates',
      label: 'Intermediates',
      icon: <Blender />,
      element: (
        <Suspense fallback={<Skeleton variant="rectangular" height="calc(100vh - 50px)" width="100vw" />}>
          <IntermediatePage />
        </Suspense>
      ),
    },
  ];
};
