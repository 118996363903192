import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AnyObject } from '@flinthills/common';
import isNil from 'lodash/isNil';

export class CognitoHelper {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {}
    private async getConfig() {
        let config;
        const localStorageConfig = localStorage.getItem('config');
        if (isNil(localStorageConfig)) {
            throw new Error('No config found in local storage');
        } else {
            config = JSON.parse(localStorageConfig);
        }
        return config;
    }
    private async initialize() {
        const config = await this.getConfig();
        Auth.configure({
            identityPoolId: config.auth.identityPoolId,
            userPoolId: config.auth.userPoolId,
            userPoolWebClientId: config.auth.userPoolWebClientId,
            region: config.auth.region,
            oauth: {
                domain: config.auth.domain,
                scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${this.baseUrl}/`,
                redirectSignOut: `${this.baseUrl}/`,
                redirectUri: `${this.baseUrl}/`,
                responseType: config.auth.authFlow === 'implicit' ? 'token' : 'code',
            },
        });
    }
    public async getUser() {
        const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser().catch(() => undefined);
        let result;
        if (user) {
            const session = (user as CognitoUser).getSignInUserSession();
            if (session) {
                const at = session.getAccessToken();
                const id = this.filterClaims(session.getIdToken().payload, 'identities', 'token_use');
                Object.keys(id).forEach((k) => {
                    if (k.startsWith('custom:')) {
                        id[k.substr(7)] = id[k];
                        delete id[k];
                    }
                });
                const {
                    email,
                    family_name,
                    given_name,
                    sub,
                    'cognito:username': username,
                    'cognito:groups': roles,
                    personnelNumber,
                    isSupervisor,
                    'pi.sri': piSri,
                    ...rest
                } = id;
                result = {
                    tokenType: 'Bearer',
                    accessToken: at.getJwtToken(),
                    expiresAt: at.getExpiration(),
                    profile: {
                        source: 'cognito',
                        sub: sub,
                        username: username,
                        firstName: given_name,
                        lastName: family_name,
                        email: email,
                        permissions: roles?.map((g: string) => g.toUpperCase()),
                        personnelNumber,
                        isSupervisor: isSupervisor === 'Yes',
                        id: username,
                        fullName: `${given_name} ${family_name}`.trim(),
                        piSri,
                        ...rest,
                    },
                };
            }
        }
        return result;
    }
    protected filterClaims(claims: AnyObject, ...extra: string[]) {
        const result = Object.assign({}, claims);
        ['nonce', 'at_hash', 'iat', 'nbf', 'exp', 'aud', 'iss', 'c_hash', ...extra].forEach((c) => delete result[c]);
        return result;
    }
    public async getToken(): Promise<string> {
        await this.initialize();
        const user = await this.getUser();
        return user ? `${user.tokenType} ${user.accessToken}` : '';
    }
}
