import { api } from '@api';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSlice } from '@redux-hooks';
import { dateFix, setHoursToZero } from '@shared/common/util';
import { IntermediateType } from '@shared/types';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  dialogRow: any;
  setOpen: (value: boolean) => void;
  open: boolean;
}

const options = [IntermediateType.PRODUCED_AT, IntermediateType.HAS_INVENTORY_OF];
export const NewRelationshipDialogBox: FC<Props> = (props: Props) => {

  /* State */
  const { dialogRow, setOpen, open } = props;

  const [saveIntermediatesProducedAt, {data: producedAtData, isLoading: producedAtIsFetching}] =
    api.useSaveIntermediatesProducedAtMutation();

  const [saveIntermediatesInventoryOf, {data: inventoryOfData, isLoading: inventoryOfIsFetching}] =
    api.useSaveIntermediatesInventoryOfMutation();

  /* Slices   */
  const [intermediateState, intermediateActions] = useSlice('intermediates');

  /* State */
  const [startDate, setStartDate] = useState<Date>(setHoursToZero(new Date()));
  const [endDate, setEndDate] = useState<Date>(dateFix(new Date('2099-01')));
  const [terminal, setTerminal] = useState<{ id: number; name: string } | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string>(dialogRow.type || '');
  const [producedAtTerminals, setProducedAtTerminals] = useState<{ id: number; name: string }[]>([]);
  const [UUID, setUUID] = useState<string>('');

  useEffect(() => {
    if (dialogRow.type === IntermediateType.HAS_INVENTORY_OF) {
      setStartDate(setHoursToZero(new Date(dialogRow.startDate)));
      setEndDate(setHoursToZero(new Date(dialogRow.endDate)));
      setSelectedOptions(dialogRow.type);
    }
    if (!dialogRow.type) {
      setStartDate(setHoursToZero(new Date()));
      setEndDate(dateFix(new Date('2099-01')));
    }
  }, [dialogRow]);

  useEffect(() => {
    if (dialogRow.terminals) {
      const terminals: any[] = [];
      dialogRow.terminals.forEach((terminal) => {
        if (terminal.type === IntermediateType.PRODUCED_AT) {
          terminals.push({ id: terminal.number, name: terminal.terminal });
          setUUID(terminal.uuid);
        }
      });
      if (terminals.length > 0) {
        setProducedAtTerminals(terminals);
      }
    }
  }, [dialogRow]);

  useEffect(() => { 
    intermediateActions.updateIsFetching(producedAtIsFetching)
  } ,[producedAtIsFetching]);

  useEffect(() => { 
    intermediateActions.updateIsFetching(inventoryOfIsFetching)
  } ,[inventoryOfIsFetching]);

  /* Functions */
  const handleSave = () => {
    if (selectedOptions === IntermediateType.PRODUCED_AT && intermediateState.terminal && UUID) {
      
      saveIntermediatesProducedAt({
        terminal: intermediateState.terminal.number.toString(),
        product: dialogRow.number.toString(),
        email: intermediateState.email || '',
      });
    }
    if (selectedOptions === IntermediateType.HAS_INVENTORY_OF && intermediateState.terminal && startDate && endDate && terminal) {
      saveIntermediatesInventoryOf({
        product: dialogRow.number.toString(),
        producedAtTerminal: terminal.id.toString(),
        terminal: intermediateState.terminal.number.toString(),
        startDate: new Date(startDate).toISOString().split('T')[0], //datesToIsoString(startDate),
        endDate: new Date(endDate).toISOString().split('T')[0], //datesToIsoString(endDate),
        email: intermediateState.email || '',
      });
    }
    
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setStartDate(setHoursToZero(new Date()));
    setEndDate(dateFix(new Date('2099-01')));
    setSelectedOptions('');
    setProducedAtTerminals([]);
  };

  return (
    <LocalizationProvider key="relationship-date" dateAdapter={AdapterDateFns}>
      <Dialog key="relationship-dialog" open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
        <DialogTitle key="relationship-dialog">
          <Typography component="div" style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>
            Create New Relationship
          </Typography>
          <Typography style={{ fontFamily: 'Times New Roman', fontSize: '18px', fontWeight: 'normal' }}>
            {dialogRow && dialogRow.name}
          </Typography>
          <Typography style={{ fontFamily: 'Courier New', fontSize: '14px', fontWeight: 'lighter' }}>
            {intermediateState.terminal && intermediateState.terminal.name}
          </Typography>
        </DialogTitle>
        <Autocomplete
          id="tags-standard"
          options={options}
          onChange={(e, v) => {
            const changeTo = v as string;
            setSelectedOptions(changeTo);
          }}
          renderInput={(params) => (
            <TextField {...params} required label="Select Type" variant="outlined" size="small" />
          )}
          value={selectedOptions}
          sx={{ m: 1 }}
        />
        {selectedOptions === IntermediateType.PRODUCED_AT ? (
          null
        ) : (
          <> </>
        )}
        {selectedOptions === IntermediateType.HAS_INVENTORY_OF ? (
          <>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                if(newValue)
                setStartDate(setHoursToZero(newValue));
              }}
              sx={{ m: 1 }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => {
                if(newValue)
                setEndDate(setHoursToZero(newValue));
              }}
              sx={{ m: 1 }}
            />
            <Autocomplete
              id="terminal-select"
              onChange={(e, v) => {
                setTerminal(v);
              }}
              options={producedAtTerminals}
              getOptionLabel={(option: { id: number; name: string }) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Select Terminal Where It's Produced"
                  variant="outlined"
                  size="small"
                />
              )}
              value={terminal}
              sx={{ m: 1 }}
            />
          </>
        ) : (
          null
        )}
        <DialogActions>
          <Button
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
